// Add your JS customizations here

jQuery(document).ready(function () {
    var timer;
    // jQuery('.column1-slide-out-div').slideDown('slow', function () {
    //     timer = setTimeout(function () {
    //         jQuery('.column1-slide-out-div').slideUp("slow");
    //     }, 5000);
    // });
    jQuery('#column1-service').click(function () {
        if (jQuery('.column1-slide-out-div').is(":hidden")) {
            jQuery('.column1-slide-out-div').slideDown('slow');
        } else {
            jQuery('.column1-slide-out-div').slideUp('slow');
            clearTimeout(timer);
        }
    });

    // jQuery('.column2-slide-out-div').slideDown('slow', function () {
    //     timer = setTimeout(function () {
    //         jQuery('.column2-slide-out-div').slideUp("slow");
    //     }, 5000);
    // });
    jQuery('#column2-service').click(function () {
        if (jQuery('.column2-slide-out-div').is(":hidden")) {
            jQuery('.column2-slide-out-div').slideDown('slow');
        } else {
            jQuery('.column2-slide-out-div').slideUp('slow');
            clearTimeout(timer);
        }
    });
});